.rectangle-footer {
  border-radius: 0;
  width: 100%;
  background: #ffffff;
  color: #000000;
  box-shadow: 0px 2px 29px rgba(31,61,97,0.05);
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  padding-top: 20px;
  height: 60px;
}

.copyright-notice-1 {
  display: none;
  margin-left: 7%;
  margin-right: 20px;
  color: #9ba4bc;
  font-family: 'Gilroy-Light';
  font-size: 14px;
}

.copyright-notice-2 {
  display: flex;
  margin-top: 20px;
  margin-left: 7%;
  margin-right: 7%;
  color: #9ba4bc;
  font-family: 'Gilroy-Light';
  font-size: 14px;
}

.footer-right-part {
  display: flex;
  flex-direction: row;
  margin-left: 7%;
  margin-right: 7%;
}
.footer-link {
  cursor: pointer;
  font-family: 'Gilroy-Light';
  font-size: 14px;
  color: #2689ff;
  margin-left: 20px;
}

@media screen and (min-width: 400px) {
  .copyright-notice-1 {
    display: flex;
  }
  .copyright-notice-2 {
    display: none;
  }
  .footer-right-part {
    margin-left: 0;
  }

  .rectangle-footer {
    justify-content: space-between;
  }
  
}
