.registration {
  height: 35px;
  font-size: 26px;
  font-family: 'Gilroy-Extrabold';
}
.field-register-form {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-family : inherit;
  text-align: left;
  margin-top: 26px;
  margin-left: 20px;
  margin-right: 20px;
}
.field-register-form label{
  height: 20px;
  color: #838eac;
  font-size: 13px;
  font-weight: 300;
  text-align: left;
}

.rectangle-reg{
  border-radius: 0;
  height: 1px;
  background: #838eac;
  color: #838eac;
}

.myrectangle{
  border-radius: 0;
  height: 1px;
  background: #838eac;
  color: #838eac;
}
.form-checkbox{
  margin-bottom: 20px;
}
.form-myitem {
  height: 25px;
  border: none;
  margin-bottom: 20px;
  color: #3b4e81;
  font-size: 16px;
  font-family: 'Gilroy-Extrabold';
}
.form-item-has-error{
  height: 25px;
  border: 1px solid red;
  margin-bottom: 20px;
  color: #3b4e81;
  font-size: 16px;
  font-family: 'Gilroy-Extrabold';
}
.form-myitem-has-error {
  height: 25px;
  border: 1px solid red;
  margin-bottom: 20px;
  color: #3b4e81;
  font-size: 16px;
  font-family: 'Gilroy-Extrabold';
}
.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.column {
  display: flex;
  flex-direction: column;
  width: 45%;
}
