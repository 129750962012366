@font-face {
  font-family: 'Gilroy-Extrabold';
  src: url('../assets/fonts/Gilroy-Extrabold.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy-Light';
  src: url('../assets/fonts/Gilroy-Light.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

.app {
  width: 100%;
  min-height: calc(100vh - 80px);
}

.bG {
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  color: #3b4e81;
  font-family: 'Gilroy-Light', Helvetica, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-stretch: normal;
  /*font-weight: 400;*/
  text-align: center;
  background-image: url('../assets/mend-bg.png');
}
.content {
  width: calc(100% - 80px);
  max-width: 660px;
  background: #ffffff;
  box-shadow: 0px 12px 73px rgba(31, 61, 97, 0.06);
  text-align: center;
  display: block;
  margin: 70px auto;
  padding: 40px;
}
.content > form {
  max-width: 409px;
  margin: 5px auto;
}
.form-item {
  width: 100%;
  max-width: 409px;
  height: 25px;
  border: none;
  margin-bottom: 20px;
  color: #3b4e81;
  font-size: 16px;
  font-family: 'Gilroy-Extrabold';
}
.rectangle {
  border-radius: 0;
  width: 100%;
  max-width: 409px;
  height: 1px;
  background: #838eac;
  color: #838eac;
}
.error {
  font-size: 10px;
  height: 34px;
  color: red;
  margin-top: 3px;
}
.content-with-table {
  display: block;
  text-align: center;
  margin-top: 30px;
  padding-bottom: 20px;
  margin-left: 7%;
  margin-right: 7%;
}

.title {
  display: inline;
  flex-direction: column;
  color: #3b4e81;
  font-family: 'Gilroy-Extrabold';
  font-size: 26px;
  justify-content: flex-end;
}

table {
  border: 1px solid gray;
  width: 100%;
  border-spacing: 0;
}

th {
  border: 1px solid gray;
  font-family: 'Gilroy-Extrabold', Helvetica, sans-serif;
  font-size: 15px;
  font-style: normal;
  /*font-weight: bold; */
}

td {
  border: 1px solid gray;
  font-family: 'Gilroy-Light', Helvetica, sans-serif;
  font-size: 15px;
  font-style: normal;
  /*font-weight: normal; */
  text-align: left;
}

.form-submit {
  border: none;
  border-radius: 0;
  width: 100px;
  height: 50px;
  background: #0076ff;
  color: #ffffff;
  font-family: 'Gilroy-Extrabold';
  font-size: 15px;
  text-align: center;
  cursor: pointer;
  box-shadow: 0px 15px 35px rgba(59, 78, 129, 0.15);
  margin: 50px auto 0px auto;
}

.button-wide {
  border: none;
  border-radius: 0;
  width: 159px;
  height: 50px;
  background: #0076ff;
  color: #ffffff;
  font-family: 'Gilroy-Extrabold';
  font-size: 15px;
  text-align: center;
  cursor: pointer;
  box-shadow: 0px 15px 35px rgba(59, 78, 129, 0.15);
  margin: 50px auto 0px auto;
}

.form-submit:disabled,
.form-submit[disabled] {
  border-radius: 0;
  width: 100px;
  height: 50px;
  font-family: 'Gilroy-Extrabold';
  font-size: 15px;
  text-align: center;
  cursor: pointer;
  box-shadow: 0px 15px 35px rgba(59, 78, 129, 0.15);
  margin: 50px auto 0px auto;
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}

.form-submit:hover {
  opacity: 0.6;
}

.h-spacer {
  margin: 0 51px 0 0;
}

.cell-v-wide {
  flex: 2;
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  justify-content: flex-start;
  padding-right: 20px;
}
.cell-v-narrow {
  flex: 1;
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  justify-content: flex-start;
  padding-right: 20px;
}
.cell-v-extra {
  flex: 1;
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-right: 20px;
}
.cell-v-wide,
.cell-v-narrow {
  display: none;
}
.property .cell-v-wide:first-of-type {
  margin-left: 10px;
}
.cell {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: white;
  padding-top: 15px;
  padding-bottom: 15px;
  box-shadow: 0px 15px 35px rgba(59, 78, 129, 0.15);
}
.cell-header {
  display: flex;
  flex-direction: row;
  font-family: 'Gilroy-Light';
  font-size: 14px;
  height: 18px;
}
.cell-data {
  display: flex;
  flex-direction: row;
  font-family: 'Gilroy-Extrabold';
  font-size: 14px;
}

@media screen and (min-width: 600px) {
  .form-submit {
    width: 159px;
  }

  .form-submit:disabled,
  .form-submit[disabled] {
    width: 159px;
  }
}

.row2 {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  margin-bottom: 5px;
}
