
.login{
  height: 35px;
  font-size: 26px;
  font-family: 'Gilroy-Extrabold';
}

.on-demandrentalmai{
  height: 34px;
  font-size: 10px;
  margin-top: 3px;
  font-family: 'Gilroy-Extrabold';
}
.field-login-form {
  max-width: 409px;
  font-size: 16px;
  font-family : inherit;
  text-align: left;
  margin-top: 26px;
}

.field-login-form label{
  height: 20px;
  color: #838eac;
  font-size: 13px;
  font-weight: 300;
  text-align: left;
}
.forgotyourpassword{
  height: 20px;
  font-size: 13px;
  text-align: center;
  margin: 20px auto 0 auto;
}

