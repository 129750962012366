.rectangle-header {
  border-radius: 0;
  width: 100%;
  height: 94px;
  background: #ffffff;
  color: #ffffff;
  box-shadow: 0px 2px 29px rgba(31,61,97,0.05);
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
}
.button-header-login {
  border: none;
  border-radius: 0;
  width: 120px;
  height: 40px;
  background: #0076ff;
  color: #ffffff;
  font-family: 'Gilroy-Extrabold';
  font-size: 15px;
  text-align: center;
  cursor: pointer;
  box-shadow: 0px 15px 35px rgba(59,78,129,0.15);
  vertical-align: middle;
  line-height: 40px;
}
.button-header-profile {
  display: none;
  border: none;
  border-radius: 0;
  width: 159px;
  height: 40px;
  background: #0076ff;
  color: #ffffff;
  font-family: 'Gilroy-Extrabold';
  font-size: 15px;
  text-align: center;
  cursor: pointer;
  box-shadow: 0px 15px 35px rgba(59,78,129,0.15);
  vertical-align: middle;
  line-height: 40px;
  margin-right: 20px;
}
.right-part {
  display: flex;
  flex-direction: row;
  margin-right: 7%;
  margin-top: 25px;
  margin-bottom: 25px;
}
.logo {
  margin-left: 7%;
  margin-top: 25px;
  margin-bottom: 25px;
  height: 46px;
}
div.button-header-profile {
  display: none;
}
button.button-header-profile {
  display: block;
  height: 50px;
  margin: 10px auto 0px auto;
}
@media screen and (min-width: 600px) {
  button.button-header-profile {
    display: none;
  }
  div.button-header-profile {
    display: block;
  }
  .button-header-login {
    width: 159px;
  }
}
