.profile{
  height: 35px;
  font-size: 26px;
}
.field-profile-form{
  max-width: 409px;
  font-size: 16px;
  font-family : inherit;
  text-align: left;
  margin-top: 26px;
}

.field-profile-form label{
  height: 20px;
  color: #838eac;
  font-size: 13px;
  font-weight: 300;
  text-align: left;
}
.form-checkbox{
  margin-bottom: 20px;
}
.form-item-has-error{
  max-width: 409px;
  height: 25px;
  border: 1px solid red;
  margin-bottom: 20px;
}

.form-item-short{
  max-width: 200px;
  height: 25px;
  border: none;
  margin-bottom: 20px;
}

.form-item-short-has-error{
  max-width: 200px;
  height: 25px;
  border: 1px solid red;
  margin-bottom: 20px;
}


