.file-upload {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
}

.file-upload-form {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
}

.file-selector {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

input[type='file'] {
  opacity: 0;
  position: absolute;
  pointer-events: none;
  width: 1px;
  height: 1px;
}

.upload-label {
  display: inline-block;
  width: 159px;
  line-height: 50px;
  cursor: pointer;
}

.upload-label-div {
  font-family: 'Gilroy-Extrabold';
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  border: #0076ff 1px solid;
  border-radius: 3px;
  width: 159px;
  height: 50px;
  background: #ffffff;
  color: #0076ff;
  font-family: 'Gilroy-Extrabold';
  font-size: 15px;
  text-align: center;
  cursor: pointer;
  box-shadow: 0px 15px 35px rgba(59, 78, 129, 0.15);
}

.upload-label-div img {
  margin-right: 15px;
}
