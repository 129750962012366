.col-hidden {
  display: none;
}
.cell-v-ava {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 20px;
  padding-right: 20px;
}
.table-class {
  border-collapse: separate;
  border-spacing: 0 5px;
}
.zxx {
  border-collapse: separate;
  border-spacing: 5em;
}
.tr-class {
  border: none;
  color: #3b4e81;
}
.td-class {
  border: none;
  padding-top: 5px;
  padding-bottom: 5px;
}
.pagination {
  display: inline-flex;
}
.pagination li {
  list-style: none;
  border: 1px solid #0076ff;
  width: 20px;
  font-family: Gilroy-Extrabold;
  border-radius: 3px;
}
.pagination li a {
  text-decoration: none;
  color: #0076ff;
}
.with-overflow {
  overflow: scroll;
  margin: 0px -10px;
  padding: 15px 10px 0;
}

@media screen and (min-width: 600px) {
  .with-overflow {
    margin: 0px -40px;
    padding: 15px 40px 0;
  }
  .cell-v-extra {
    display: none;
  }
  .cell-v-wide,
  .cell-v-narrow {
    display: block;
  }
}
